import axios from "axios";
import qs from "qs";

/**
 * @name callAPI
 * @description API wrapper to provide consistent responses no matter the underlying fetch tech used.
 * @param {object} data added to the body of a request for DELETE / PATCH / POST / PUT actions
 * @param {object} headers
 * @param {string} method REST method
 * @param {object} params added to the request string for GET actions
 * @param {string} url endpoint url
 * @param {string} version API version
 * @returns {object} response object
 */
const callAPI = async ({
  data = {},
  headers = {},
  method = "get",
  params = {},
  url,
  version = "v1",
}) => {
  const response = await axios({
    baseURL: `https://api.thetitlebelt.com/${version}/`,
    data: qs.stringify(data),
    headers: {
      "content-type": "application/x-www-form-urlencoded",
      ...headers,
    },
    method,
    params,
    url,
  });

  return response;
};

export default callAPI;
