import React, { useEffect, useState } from 'react';
// npm imports
import { useParams } from 'react-router-dom';
// mui imports
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// local imports
import { getNFLFranchise } from '../../../api';
import Alert from '../../Alert';
import NotFound from '../../NotFound';
import FranchiseHistory from './FranchiseHistory';
import FranchiseInfo from './FranchiseInfo';
import FranchiseStats from './FranchiseStats';

export default function Teams() {
  const { franchiseSlug } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [franchise, setFranchise] = useState(null);

  useEffect(() => {
    if (franchiseSlug) {
      setIsLoading(true);
      getNFLFranchise({ franchiseSlug })
        .then((response) => {
          setFranchise(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
          console.error(error);
        });
    }
  }, [franchiseSlug]);

  if (error) {
    return (
      <NotFound title="Not Found" subtitle="This team could not be found." />
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Alert />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FranchiseInfo franchise={franchise} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <FranchiseStats franchise={franchise} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <ins
            classNam="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-4772032915698611"
            data-ad-slot="4528541094"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </Grid>
        <Grid item xs={12}>
          <FranchiseHistory franchise={franchise} isLoading={isLoading} />
        </Grid>
        <Grid item xs={12}>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="ca-pub-4772032915698611"
            data-ad-slot="7074813385"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </Grid>
      </Grid>
    </Container>
  );
}
