import React from "react";
//npm imports
import { BrowserRouter as Router } from "react-router-dom";
// mui imports
import { ThemeProvider } from "@mui/material/styles";
// local imports
import theme from "./client/styles/theme";
import AppLayout from "./AppLayout";

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AppLayout />
      </ThemeProvider>
    </Router>
  );
}

export default App;
