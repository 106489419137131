import React from 'react';
// mui imports
import Container from '@mui/material/Container';
// local imports
import Alert from '../Alert';
import About from './About';
import Champion from './Champion';
import Current from './Current';
import Seasons from './Seasons';
import Stats from './Stats';

export default function NFLTitleBelt() {
  const isSeasonOver = false;
  return (
    <>
      <Alert />
      {isSeasonOver ? <Champion /> : <Current />}
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-4772032915698611"
          data-ad-slot="1672127676"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </Container>
      <Seasons />
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <ins
          classNam="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-4772032915698611"
          data-ad-slot="4528541094"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </Container>
      <Stats />
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <ins
          className="adsbygoogle"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-4772032915698611"
          data-ad-slot="7074813385"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </Container>
      <About />
    </>
  );
}
