import React, { useState } from 'react';
import PropTypes from 'prop-types';
// mui imports
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// local imports
import {
  NavigationAppBar,
  NavigationDrawer,
} from './client/layouts/Navigation';
export default function AppContainer({ children }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavigationAppBar
        handleDrawerOpen={handleDrawerOpen}
        isDrawerOpen={isDrawerOpen}
      />
      <NavigationDrawer
        handleDrawerClose={handleDrawerClose}
        isDrawerOpen={isDrawerOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflow: 'auto',
          transition: isDrawerOpen
            ? (theme) =>
                theme.transitions.create('margin', {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                })
            : (theme) =>
                theme.transitions.create('margin', {
                  easing: (theme) => theme.transitions.easing.sharp,
                  duration: (theme) => theme.transitions.duration.leavingScreen,
                }),
          marginLeft: isDrawerOpen
            ? 0
            : (theme) => `-${theme.navigation.drawerWidth}px`,
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

AppContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
