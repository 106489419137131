import React from 'react';
import PropTypes from 'prop-types';
// npm imports
import { Link as RouterLink } from 'react-router-dom';
// mui imports
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// mui icons
import InstagramIcon from '@mui/icons-material/Instagram';
import MenuIcon from '@mui/icons-material/Menu';
import TwitterIcon from '@mui/icons-material/Twitter';
// local imports

export default function NavigationAppBar({ handleDrawerOpen, isDrawerOpen }) {
  return (
    <AppBar
      position="fixed"
      sx={{
        flexGrow: 1,
        transition: isDrawerOpen
          ? (theme) =>
              theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              })
          : (theme) =>
              theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
        marginLeft: isDrawerOpen
          ? (theme) => theme.navigation.drawerWidth
          : undefined,
        width: isDrawerOpen
          ? (theme) => `calc(100% - ${theme.navigation.drawerWidth}px)`
          : undefined,
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="open drawer"
          color="inherit"
          edge="start"
          onClick={handleDrawerOpen}
          size="large"
          sx={{ marginRight: 2, ...(isDrawerOpen && { display: 'none' }) }} // TODO: resolve why this isn't hiding (Mui-ButtonBase-root issue)
        >
          <MenuIcon />
        </IconButton>

        <RouterLink as="span" to="/">
          <img
            alt="The NFL Title Belt"
            src="/the-title-belt-logo.png"
            width={48}
            height={48}
          />
        </RouterLink>
        <Typography component="h1" variant="h4" color="inherit" noWrap>
          NFL Title Belt
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Link
            href="https://instagram.com/thetitlebelt"
            target="_blank"
            style={{ color: '#ffffff' }}
          >
            <IconButton
              size="large"
              aria-label="The Title Belt Instagram"
              color="inherit"
            >
              <InstagramIcon />
            </IconButton>
          </Link>
          <Link
            href="https://twitter.com/thetitlebelt"
            target="_blank"
            style={{ color: '#ffffff' }}
          >
            <IconButton
              size="large"
              aria-label="The Title Belt Twitter"
              color="inherit"
            >
              <TwitterIcon />
            </IconButton>
          </Link>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

NavigationAppBar.propTypes = {
  handleDrawerOpen: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
};
