import React from 'react';
// local imports
import AppContainer from './AppContainer';
import AppRoutes from './AppRoutes';
import Footer from './client/layouts/Footer';

export default function AppLayout() {
  return (
    <AppContainer>
      <AppRoutes />
      <Footer />
    </AppContainer>
  );
}
