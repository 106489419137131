import React from 'react';
import { format } from 'date-fns';
//mui imports
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    description: 'Date of game',
    flex: 1,
    minWidth: 275,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: (params) => {
      let weekDisplay = `Week ${params?.value?.week}`;
      if (params?.value?.week === '20') {
        weekDisplay = 'WC round';
      }
      if (params?.value?.week === '21') {
        weekDisplay = 'Div round';
      }
      if (params?.value?.week === '22') {
        weekDisplay = 'Con round';
      }
      if (params?.value?.week === '23') {
        weekDisplay = 'Super Bowl';
      }
      return `${params?.value?.season} ${weekDisplay} (${format(
        new Date(params?.value?.date),
        'MMMM d, yyyy'
      )})`;
    },
  },
  {
    field: 'winningTeam',
    headerName: 'Winning Team',
    description: 'Winning team.',
    flex: 1,
    minWidth: 230,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          <i
            className={`logo-icon logo-icon-2x logo-nfl-${params?.value?.teamAbbr?.toLowerCase()}-${
              params?.value?.year
            }`}
          />{' '}
          <span style={{ marginLeft: '12px' }}>{params?.value?.teamName}</span>
        </>
      );
    },
  },
  {
    field: 'losingTeam',
    headerName: 'Losing Team',
    description: 'Losing team.',
    flex: 1,
    minWidth: 230,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          <i
            className={`logo-icon logo-icon-2x logo-nfl-${params?.value?.teamAbbr?.toLowerCase()}-${
              params?.value?.year
            }`}
          />{' '}
          <span style={{ marginLeft: '12px' }}>{params?.value?.teamName}</span>
        </>
      );
    },
  },
  {
    field: 'score',
    headerName: 'Score',
    description: 'Final score of the matchup',
    flex: 1,
    minWidth: 115,
    disableColumnMenu: true,
    sortable: false,
  },
];

export default function MatchupHistoryTable({ history, isLoading }) {
  const rows = history?.matchups?.map((matchup) => {
    return {
      id: matchup.beltId,
      date: {
        date: matchup.matchDate,
        season: matchup.seasonId,
        week: matchup.weekId,
      },
      losingTeam: {
        teamName: matchup.extras.loserTeamName,
        teamAbbr: matchup.extras.loserAbbr,
        year: matchup.seasonId,
      },
      winningTeam: {
        teamName: matchup.extras.winnerTeamName,
        teamAbbr: matchup.extras.winnerAbbr,
        year: matchup.seasonId,
      },
      score: matchup.matchScore,
      team: matchup.beltId,
    };
  });

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        pt: 4,
        pb: 0,
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={32}
        disableSelectionOnClick
        hideFooter
        autoHeight={true}
        loading={isLoading}
      />
    </Box>
  );
}
