import React from "react";
// mui imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
// local imports

export default function Footer() {
  return (
    <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
      <Typography variant="h6" align="center" gutterBottom>
        The Title Belt
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
      >
        Tracking the mythical NFL Title Belt.
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        All team names, logos, and likenesses are property of their respective
        owners and leagues.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Link
          href="https://instagram.com/thetitlebelt"
          target="_blank"
          underline="hover">
          <IconButton
            size="large"
            aria-label="The Title Belt Instagram"
            color="inherit"
          >
            <InstagramIcon />
          </IconButton>
        </Link>
        <Link href="https://twitter.com/thetitlebelt" target="_blank" underline="hover">
          <IconButton
            size="large"
            aria-label="The Title Belt Twitter"
            color="inherit"
          >
            <TwitterIcon />
          </IconButton>
        </Link>
      </Box>
      <Typography variant="body2" color="text.secondary" align="center">
        © 2013 - {new Date().getFullYear()}{" "}
        <Link href="https://codeandsons.com/" target="_blank" underline="hover">
          Code & Sons
        </Link>
        {". "}
        All rights reserved.
      </Typography>
    </Box>
  );
}
