import React from "react";
// mui imports
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

export default function FranchiseInfo({ franchise, isLoading }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints?.down("sm"));

  return (
    <Grid container alignItems="center" columnSpacing={{ xs: 1, md: 2 }}>
      <Grid item>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            sx={{ height: { xs: 50, md: 75 }, width: { xs: 50, md: 75 } }}
          />
        ) : (
          <i
            className={`logo-icon logo-icon-${
              isMobile ? "medium" : "large"
            } logo-nfl-${franchise?.abbr.toLowerCase()}`}
          />
        )}
      </Grid>
      <Grid item>
        <Typography
          component="h1"
          variant={isMobile ? "h5" : "h3"}
          align="left"
        >
          {isLoading ? (
            <Skeleton animation="wave" sx={{ width: { xs: 200, md: 500 } }} />
          ) : (
            franchise?.name
          )}
        </Typography>
        <Typography variant="overline" align="left">
          {isLoading ? (
            <Skeleton animation="wave" sx={{ width: { xs: 175, md: 450 } }} />
          ) : (
            `${franchise.beltInfo.wins}-${franchise.beltInfo.losses} record in Title Belt matches`
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}
