import callAPI from "./callAPI";

/**
 * @name getNFLAlerts
 * @description get any alerts regarding NFL belt
 * @returns {object} response object
 */
export const getNFLAlerts = () => {
  return callAPI({ url: `/nfl/alerts/` });
};

/**
 * @name getNFLCurrentMatchup
 * @description get the current NFL Title Belt matchup
 * @returns {object} response object
 */
export const getNFLCurrentMatchup = () => {
  return callAPI({ url: `/nfl/current/` });
};

/**
 * @name getNFLSeasons
 * @description get the Title Belt data for each NFL season
 * @returns {object} response object
 */
export const getNFLSeasons = () => {
  return callAPI({ url: `/nfl/seasons/` });
};

/**
 * @name getNFLStats
 * @description get the all-time stats for the NFL Title Belt
 * @returns {object} response object
 */
export const getNFLStats = () => {
  return callAPI({ url: `/nfl/stats/` });
};

/**
 * @name getNFLFranchise
 * @description get the NFL Title Belt stats for a single franchise
 */
export const getNFLFranchise = ({ franchiseSlug }) => {
  return callAPI({ url: `/nfl/teams/`, params: { franchiseSlug } });
};

/**
 * @name getNFLFranchises
 * @description get all NFL franchises
 * @returns {object} response object
 */
export const getNFLFranchises = () => {
  return callAPI({ url: `/nfl/teams/` });
};
