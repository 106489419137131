import React, { useEffect } from 'react';
// npm imports
import { Route, Switch, useLocation } from 'react-router-dom';
// local imports
import About from './client/components/NFL/About';
import Franchise from './client/components/NFL/Franchise';
import NFLTitleBelt from './client/components/NFL';

export default function AppRoutes() {
  // scroll to top when route changes
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return (
    <Switch>
      {/* /teams */}
      <Route path="/nfl">
        <Switch>
          <Route path="/nfl/:franchiseSlug">
            <Franchise />
          </Route>
          <Route path="/nfl">
            <NFLTitleBelt />
          </Route>
        </Switch>
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/">
        <NFLTitleBelt />
      </Route>
    </Switch>
  );
}
