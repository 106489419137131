import React from 'react';
import { format } from 'date-fns';
//mui imports
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
//mui icons
import SportsFootballIcon from '@mui/icons-material/SportsFootball';

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    description: 'Date of game',
    flex: 1,
    minWidth: 275,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: (params) => {
      let weekDisplay = `Week ${params?.value?.week}`;
      if (params?.value?.week === '20') {
        weekDisplay = 'WC round';
      }
      if (params?.value?.week === '21') {
        weekDisplay = 'Div round';
      }
      if (params?.value?.week === '22') {
        weekDisplay = 'Con round';
      }
      if (params?.value?.week === '23') {
        weekDisplay = 'Super Bowl';
      }
      return `${params?.value?.season} ${weekDisplay} (${format(
        new Date(params?.value?.date),
        'MMMM d, yyyy'
      )})`;
    },
  },
  {
    field: 'winningTeam',
    headerName: 'Winning Team',
    description: 'Winning team.',
    flex: 1,
    minWidth: 230,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => {
      return (
        <>
          <i
            className={`logo-icon logo-icon-2x logo-nfl-${params?.value?.teamAbbr?.toLowerCase()}-${
              params?.value?.year
            }`}
          />{' '}
          <span style={{ marginLeft: '12px' }}>{params?.value?.teamName}</span>
        </>
      );
    },
  },
  {
    field: 'losingTeam',
    headerName: 'Losing Team',
    description: 'Losing team.',
    flex: 1,
    minWidth: 230,
    disableColumnMenu: true,
    sortable: false,
    renderCell: (params) => {
      if (!params?.value?.teamName) {
        return (
          <>
            <SportsFootballIcon />{' '}
            <span style={{ marginLeft: '12px' }}>BYE</span>
          </>
        );
      }
      return (
        <>
          <i
            className={`logo-icon logo-icon-2x logo-nfl-${params?.value?.teamAbbr?.toLowerCase()}-${
              params?.value?.year
            }`}
          />{' '}
          <span style={{ marginLeft: '12px' }}>{params?.value?.teamName}</span>
        </>
      );
    },
  },
  {
    field: 'score',
    headerName: 'Score',
    description: 'Final score of the matchup',
    flex: 1,
    minWidth: 115,
    disableColumnMenu: true,
    sortable: false,
  },
];

export default function FranchiseHistory({ franchise, isLoading }) {
  const rows = franchise?.matchups?.map((matchup) => {
    return {
      id: matchup.beltId,
      date: {
        date: matchup.matchDate,
        season: matchup.seasonId,
        week: matchup.weekId,
      },
      losingTeam: {
        teamName: matchup.extras.loserTeamName,
        teamAbbr: matchup.extras.loserAbbr,
        year: matchup.seasonId,
      },
      winningTeam: {
        teamName: matchup.extras.winnerTeamName,
        teamAbbr: matchup.extras.winnerAbbr,
        year: matchup.seasonId,
      },
      score: matchup.matchScore,
      team: matchup.beltId,
    };
  });

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography component="h2" variant="h5" align="left">
          {isLoading ? (
            <Skeleton />
          ) : (
            `Full history of the ${franchise?.name} Title Belt matches`
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Skeleton height={350} />
        ) : franchise?.matchups?.length > 0 ? (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight={true}
            loading={isLoading}
          />
        ) : (
          <Typography component="h2" variant="h5" align="left">
            {isLoading ? (
              <Skeleton />
            ) : (
              `We are experiencing some issues loading historical Title Belt matchup data.`
            )}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
