import React from 'react';
import PropTypes from 'prop-types';
// npm imports
import { NavLink } from 'react-router-dom';
// mui imports
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Toolbar from '@mui/material/Toolbar';
// mui icons
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// local imports
import NFLDivisionMenu from './NFL/NFLDivisionMenu';

export default function NavigationDrawer({ handleDrawerClose, isDrawerOpen }) {
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={isDrawerOpen}
      sx={{
        '& .MuiDrawer-paper': {
          width: (theme) => theme.navigation.drawerWidth,
          boxSizing: 'border-box',
        },
        flexShrink: 0,
        width: (theme) => theme.navigation.drawerWidth,
      }}
    >
      <Toolbar
        disableGutters
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          pl: 1.5,
        }}
      >
        <IconButton onClick={handleDrawerClose}>
          <MenuOpenIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List
        sx={{
          '& .active': {
            backgroundColor: (theme) => theme.palette.secondary.main,
          },
        }}
      >
        <ListItem button component={NavLink} exact key="Home" to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={NavLink} exact key="About" to="/about">
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItem>
        <ListSubheader component="div" id="nested-list-subheader">
          Browse by team
        </ListSubheader>
        <NFLDivisionMenu conference="AFC" division="East" />
        <NFLDivisionMenu conference="AFC" division="North" />
        <NFLDivisionMenu conference="AFC" division="South" />
        <NFLDivisionMenu conference="AFC" division="West" />
        <NFLDivisionMenu conference="NFC" division="East" />
        <NFLDivisionMenu conference="NFC" division="North" />
        <NFLDivisionMenu conference="NFC" division="South" />
        <NFLDivisionMenu conference="NFC" division="West" />
      </List>
    </Drawer>
  );
}

NavigationDrawer.propTypes = {
  handleDrawerClose: PropTypes.func.isRequired,
  isDrawerOpen: PropTypes.bool.isRequired,
};
