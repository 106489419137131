import React, { useEffect, useState } from 'react';
// mui imports
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
// local imports
import { getNFLSeasons } from '../../../api';

export default function Seasons() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [seasons, setSeasons] = useState([]);

  useEffect(() => {
    getNFLSeasons()
      .then((response) => {
        setSeasons(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (error) {
    return (
      <Container maxWidth="xl" sx={{ pt: 4, pb: 4 }}>
        <Typography
          component="h2"
          variant="h4"
          align="center"
          justify="center"
          color="text.primary"
          gutterBottom
        >
          There was an issue loading the NFL Title Belt seasons table.
        </Typography>
      </Container>
    );
  }

  // data for headers - set week numbers in first row
  const headerColumns = [{ title: '' }];
  for (let i = 1; i <= 18; i++) {
    headerColumns.push({ title: i });
  }
  headerColumns.push(
    { title: 'WC' },
    { title: 'DIV' },
    { title: 'CON' },
    { title: 'SB' }
  );

  // data for each row
  const rows = [];
  seasons.forEach((season) => {
    rows.push({ season: season.season, weeks: season.weeks });
  });

  const tableBody = () => {
    return rows.map((row) => {
      // first column is season year
      return (
        <TableRow
          key={row.name}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
            height: '50px',
          }}
        >
          <TableCell
            align="center"
            component="th"
            padding="none"
            scope="row"
            sx={{
              height: '50px',
              maxHeight: '50px',
              minHeight: '50px',
              width: '50px',
              maxWidth: '50px',
              minWidth: '50px',
              backgroundColor: '#000000',
              color: '#ffffff',
            }}
          >
            {row.season}
          </TableCell>
          {row.weeks.map((week) => {
            let winnerDisplayName = week.winnerFranchise;
            if (week.winnerFranchise !== week.winnerTeam) {
              winnerDisplayName = `${week.winnerTeam} (${week.winnerFranchise})`;
            }

            let loserDisplayName = week.loserFranchise;
            if (week.loserFranchise !== week.loserTeam) {
              loserDisplayName = `${week.loserTeam} (${week.loserFranchise})`;
            }

            let beltDescription = null;
            if (week.active === '1') {
              beltDescription = `The ${winnerDisplayName} defeated the ${loserDisplayName} ${week.matchScore}.`;
            } else if (week.active === '0' && week.franchiseId) {
              beltDescription = `The ${week.winnerTeam} did not have a game this week.`;
            }
            return (
              <TableCell
                align="center"
                padding="none"
                sx={{
                  borderLeft: 'solid 1px #808080',
                  height: '50px',
                  maxHeight: '50px',
                  minHeight: '50px',
                  width: '50px',
                  maxWidth: '50px',
                  minWidth: '50px',
                  backgroundColor: week.winnerPrimaryColor
                    ? `#${week.winnerPrimaryColor}CC`
                    : row.season < new Date().getFullYear()
                    ? '#000000CC'
                    : '#ffffff',
                  opacity:
                    week.winnerPrimaryColor && week.active === '0' ? 0.5 : 1,
                }}
                id={`${week.seasonId}-week-${week.weekId}`}
              >
                {beltDescription ? (
                  <Tooltip
                    title={beltDescription}
                    placement="top"
                    arrow
                    enterTouchDelay={10}
                    leaveTouchDelay={7500}
                  >
                    <i
                      className={`logo-icon logo-icon-2x logo-nfl-${week?.abbr?.toLowerCase()}-${
                        row.season
                      }`}
                    />
                  </Tooltip>
                ) : (
                  <i
                    className={`logo-icon logo-icon-2x logo-nfl-${week?.abbr?.toLowerCase()}-${
                      row.season
                    }`}
                  />
                )}
              </TableCell>
            );
          })}
        </TableRow>
      );
    });
  };

  return (
    <Container maxWidth="xl" sx={{ pt: 4, pb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs="12">
          {isLoading ? (
            <Skeleton height={350} />
          ) : (
            <TableContainer sx={{ width: '100%' }} component={Paper}>
              <Table
                sx={{ width: '100%' }}
                aria-label="NFL Title Belt Seasons"
                table-layout="fixed"
              >
                <TableHead>
                  <TableRow
                    key="header"
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      height: '50px',
                    }}
                  >
                    {headerColumns.map((column) => (
                      <TableCell
                        align="center"
                        component="th"
                        key={column.title}
                        padding="none"
                        scope="row"
                        sx={{
                          height: '50px',
                          maxHeight: '50px',
                          minHeight: '50px',
                          width: '50px',
                          maxWidth: '50px',
                          minWidth: '50px',
                          backgroundColor: '#000000',
                          color: '#ffffff',
                        }}
                      >
                        {column.title}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>{tableBody()}</TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
