import React from 'react';
// mui imports
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function About() {
  return (
    <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
      <Card raised={true}>
        <CardHeader title="About the NFL Title Belt" />
        <CardContent>
          <Typography variant="p" color="text.primary" paragraph>
            The NFL Title Belt was inspired by a{' '}
            <Link
              href="http://www.reddit.com/r/nfl/comments/1h223y/following_the_nfl_belt/"
              target="_blank"
              underline="hover"
            >
              thread on Reddit
            </Link>
            , and was previously tracked on our partner site,{' '}
            <Link
              href="https://playoffmagic.com/"
              target="_blank"
              underline="hover"
            >
              Playoff Magic
            </Link>
            .
          </Typography>
          <Typography variant="p" color="text.primary" paragraph>
            When the Green Bay Packers won Super Bowl I, they started the belt.
            The next team they lost to in the '67 season, the Minnesota Vikings
            in Week 5, took the belt from the Packers. The next team to beat the
            Vikings, the Atlanta Falcons in Week 7, took it from Minnesota, and
            so on. The belt is not lost during ties, and if the current belt
            holder fails to make the playoffs, the belt also misses the
            playoffs, and the current holder starts the next season with the
            belt.
          </Typography>
          <Typography variant="p" color="text.primary" paragraph>
            <ul>
              <li>
                Every team has held the Title Belt at some point during their
                existence.
              </li>
              <li>
                The Title Belt is active any week where an NFL game is played,
                regardless if the current Title Belt holder plays. This means
                the current Title Belt holder can keep the Title Belt on a bye
                week, or during a playoff week when they are not playing or did
                not qualify. Bye weeks and weeks where the Title Belt holder is
                not qualified to play are indicated by a faded logo.
              </li>
              <li>
                The Title Belt is inactive any week where no NFL game is played.
                This means during the offseason or during a strike.
              </li>
              <li>
                Title Belt statistics stay with a franchise, regardless of
                location.
                <ul>
                  <li>
                    The Arizona Cardinals were the St. Louis Cardinals prior to
                    the 1988 season, and the Phoenix Cardinals from 1988 - 1993.
                  </li>
                  <li>
                    The Cleveland Browns and Baltimore Ravens are not the same
                    franchise. The Cleveland Browns were active from 1967 -
                    1995, and again from 1999 - present. The Baltimore Ravens
                    began play in 1996.
                  </li>
                  <li>
                    The Indianapolis Colts were the Baltimore Colts prior to the
                    1984 season.
                  </li>
                  <li>
                    The Las Vegas Raiders were the Oakland Raiders from 1960 -
                    1981 and again from 1995 - 2019, and the Los Angeles Raiders
                    from 1982 - 1994.
                  </li>
                  <li>
                    The Los Angeles Chargers were the San Diego Chargers from
                    1961 - 2016.
                  </li>
                  <li>
                    The Los Angeles Rams were the St. Louis Rams from 1995 -
                    2015.
                  </li>
                  <li>
                    The Tennessee Titans were the Houston Oilers prior to the
                    1997 season, and the Tennessee Oilers from 1998 - 1999.
                  </li>
                  <li>
                    The Washington Commanders were the Washington Redskins prior to the 2020 season, and the Washington Football Team from 2020 - 2021.
                  </li>
                </ul>
              </li>
            </ul>
          </Typography>
          <Typography>
            Game stats from{' '}
            <Link
              href="https://pro-football-reference.com/"
              target="_blank"
              underline="hover"
            >
              ProFootballReference.com
            </Link>
            . Team logos and colors from{' '}
            <Link href="https://logocdn.com" target="_blank" underline="hover">
              LogoCDN
            </Link>
            .
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}
