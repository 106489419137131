import { createTheme } from '@mui/material/styles';

const themeName = 'NFL GOLD';
const navigation = { drawerWidth: 240 };
const palette = {
  mode: 'light',
  primary: {
    main: '#53565A',
  },
  secondary: {
    main: '#D29F13',
  },
};

export default createTheme({ navigation, palette, themeName });
