import React from "react";
import { Link } from "react-router-dom";
// mui imports
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function NotFound() {
  return (
    <Grid
      alignItems="center"
      direction="column"
      container
      justifyContent="center"
      sx={{ padding: 10 }}
    >
      <Grid item xs={12}>
        <Typography variant="h2" component="h1">
          Page not found.
        </Typography>
        <Divider sx={{ marginTop: 4, marginBottom: 4 }} />
        <Link to="/" variant="body">
          Go to the homepage.
        </Link>
      </Grid>
    </Grid>
  );
}
