import React from "react";
// mui imports
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

export default function FranchiseStats({ franchise, isLoading }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {isLoading ? (
                <Skeleton animation="wave" width={150} />
              ) : (
                "Total weeks"
              )}
            </Typography>
            <Typography variant="h5" component="div">
              {isLoading ? (
                <Skeleton animation="wave" width={50} />
              ) : (
                franchise?.beltInfo?.totalWeeks
              )}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {/* {isLoading ? <Skeleton animation="wave" width={190} /> : "1st"} */}
            </Typography>
            <Typography variant="body2">
              {isLoading ? (
                <>
                  <Skeleton animation="wave" width={300} />
                  <Skeleton animation="wave" width={125} />
                </>
              ) : (
                "Total weeks holding the Title Belt, including inactive weeks."
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {isLoading ? <Skeleton animation="wave" width={150} /> : "Wins"}
            </Typography>
            <Typography variant="h5" component="div">
              {isLoading ? (
                <Skeleton animation="wave" width={50} />
              ) : (
                franchise?.beltInfo?.wins
              )}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {/* {isLoading ? <Skeleton animation="wave" width={190} /> : "T-3rd"} */}
            </Typography>
            <Typography variant="body2">
              {isLoading ? (
                <>
                  <Skeleton animation="wave" width={300} />
                  <Skeleton animation="wave" width={125} />
                </>
              ) : (
                "Wins in active Title Belt matches, as the reigning holder or challenger."
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {isLoading ? <Skeleton animation="wave" width={150} /> : "Losses"}
            </Typography>
            <Typography variant="h5" component="div">
              {isLoading ? (
                <Skeleton animation="wave" width={50} />
              ) : (
                franchise?.beltInfo?.losses
              )}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {/* {isLoading ? <Skeleton animation="wave" width={190} /> : "T-6th"} */}
            </Typography>
            <Typography variant="body2">
              {isLoading ? (
                <>
                  <Skeleton animation="wave" width={300} />
                  <Skeleton animation="wave" width={125} />
                </>
              ) : (
                "Losses in active Title Belt matches, as the reigning holder or challenger."
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {isLoading ? (
                <Skeleton animation="wave" width={150} />
              ) : (
                "Longest Reign"
              )}
            </Typography>
            <Typography variant="h5" component="div">
              {isLoading ? (
                <Skeleton animation="wave" width={50} />
              ) : (
                franchise?.beltInfo?.longestReign
              )}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {/* {isLoading ? <Skeleton animation="wave" width={190} /> : "1st"} */}
            </Typography>
            <Typography variant="body2">
              {isLoading ? (
                <>
                  <Skeleton animation="wave" width={300} />
                  <Skeleton animation="wave" width={125} />
                </>
              ) : (
                "Longest streak of consecutive weeks as Title Belt holder."
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {isLoading ? (
                <Skeleton animation="wave" width={150} />
              ) : (
                "Season Last Held"
              )}
            </Typography>
            <Typography variant="h5" component="div">
              {isLoading ? (
                <Skeleton animation="wave" width={50} />
              ) : (
                franchise?.beltInfo?.mostRecent
              )}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {/* {isLoading ? <Skeleton animation="wave" width={190} /> : "T-3rd"} */}
            </Typography>
            <Typography variant="body2">
              {isLoading ? (
                <>
                  <Skeleton animation="wave" width={300} />
                  <Skeleton animation="wave" width={125} />
                </>
              ) : (
                "NFL Season / year in which the Title Belt was last held."
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              gutterBottom
            >
              {isLoading ? (
                <Skeleton animation="wave" width={150} />
              ) : (
                "Consecutive Losses"
              )}
            </Typography>
            <Typography variant="h5" component="div">
              {isLoading ? (
                <Skeleton animation="wave" width={50} />
              ) : (
                franchise?.beltInfo?.lossesSinceReign || "0 - Current holder"
              )}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {/* {isLoading ? <Skeleton animation="wave" width={190} /> : "T-2nd"} */}
            </Typography>
            <Typography variant="body2">
              {isLoading ? (
                <>
                  <Skeleton animation="wave" width={300} />
                  <Skeleton animation="wave" width={125} />
                </>
              ) : (
                "Losses in Title Belt matches since last holding the belt."
              )}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
