import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// mui imports
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
// local imports
import { getNFLStats } from '../../../api';

const columns = [
  {
    field: 'team',
    headerName: 'Team',
    description: 'Franchise name',
    minWidth: 250,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <Link
          to={`/nfl/${params?.value?.slug}`}
          variant="body"
          style={{ color: 'inherit' }}
        >
          <i
            className={`logo-icon logo-icon-2x logo-nfl-${params?.value?.abbr.toLowerCase()}`}
          />{' '}
          <span style={{ marginLeft: '16px' }}>{params?.value?.name}</span>
        </Link>
      );
    },
  },
  {
    field: 'totalWeeks',
    headerName: 'Total Weeks',
    type: 'number',
    description:
      'Total weeks holding the Title Belt, including inactive weeks.',
    width: 140,
    disableColumnMenu: true,
  },
  {
    field: 'activeWeeks',
    headerName: 'Wins',
    type: 'number',
    description:
      'Wins in active Title Belt matches, as the reigning holder or challenger.',
    width: 140,
    disableColumnMenu: true,
  },
  {
    field: 'losses',
    headerName: 'Losses',
    type: 'number',
    description:
      'Losses in active Title Belt matches, as the reigning holder or challenger.',
    width: 140,
    disableColumnMenu: true,
  },
  {
    field: 'longestReign',
    headerName: 'Longest Reign',
    type: 'number',
    description: 'Longest streak of consecutive weeks as Title Belt holder.',
    width: 155,
    disableColumnMenu: true,
  },
  {
    field: 'mostRecent',
    headerName: 'Season Last Held',
    type: 'number',
    description: 'NFL Season / year in which the Title Belt was last held.',
    width: 175,
    disableColumnMenu: true,
  },
  {
    field: 'lossesSinceReign',
    headerName: 'Consecutive Losses',
    type: 'number',
    description: 'Losses in Title Belt matches since last holding the belt.',
    width: 190,
    disableColumnMenu: true,
  },
  {
    field: 'reigns',
    headerName: 'Total Reigns',
    type: 'number',
    description: 'Separate reigns as the Title Belt holder.',
    width: 140,
    disableColumnMenu: true,
  },
];

export default function Stats() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState([]);

  const rows = stats?.map((stat) => {
    return {
      id: stat.franchiseId,
      abbr: stat.abbr,
      activeWeeks: stat.activeWeeks,
      longestReign: stat.longestReign,
      losses: stat.losses,
      lossesSinceReign: stat.lossesSinceReign || 'Current holder',
      mostRecent: stat.mostRecent,
      reigns: stat.reigns,
      team: {
        abbr: stat.abbr,
        name: stat.teamName,
        slug: stat.slug,
      },
      totalWeeks: stat.totalWeeks,
    };
  });

  useEffect(() => {
    getNFLStats()
      .then((response) => {
        setStats(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <Typography
          component="h2"
          variant="h4"
          align="center"
          justify="center"
          color="text.primary"
          gutterBottom
        >
          There was an issue loading the NFL Title Belt stats table.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ pt: 4, pb: 4 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={32}
        disableSelectionOnClick
        hideFooter
        autoHeight={true}
        loading={isLoading}
      />
    </Container>
  );
}
