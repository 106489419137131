import React, { useEffect, useState } from 'react';
// mui imports
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { getNFLAlerts } from '../../api';

export default function Alert() {
  const [isLoading, setIsLoading] = useState(true);
  const [alerts, setAlerts] = useState(null);

  useEffect(() => {
    getNFLAlerts()
      .then((response) => {
        setAlerts(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  if (!isLoading && alerts?.length) {
    return (
      <Container maxWidth="xl" sx={{ pt: 4, pb: 4 }}>
        <Stack spacing={2}>
          {alerts?.map((alert) => {
            return (
              <MuiAlert key={alert.title} severity={alert.type}>
                <AlertTitle>{alert.title}</AlertTitle>
                {alert.description}
              </MuiAlert>
            );
          })}
        </Stack>
      </Container>
    );
  }

  return null;
}
