import React, { Fragment, useState } from 'react';
//npm imports
import { NavLink } from 'react-router-dom';
// mui imports
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// mui icons
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
// local imports
import allFranchisesByDivision from '../franchisesList';

export default function NFLDivisionMenu({ conference, division }) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const currentDivision = allFranchisesByDivision.find(
    (filteredDivision) => filteredDivision.name === `${conference} ${division}`
  );

  return (
    <Fragment>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <i
            className={`logo-icon logo-icon-2x logo-nfl-${conference.toLowerCase()}`}
          />
        </ListItemIcon>
        <ListItemText primary={currentDivision?.name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {currentDivision?.teams?.map((team) => (
            <ListItemButton
              component={NavLink}
              exact
              key={team.name}
              sx={{
                paddingLeft: 4,
                paddingRight: 0,
                paddingTop: 0.5,
                paddingBottom: 0.5,
              }}
              to={`/nfl/${team.slug}`}
              // activeClassName={classes.activeNav}
              // TODO: add active class. example:
              // activeNav: {
              //     backgroundColor: theme.palette.secondary.main,
              //     fontWeight: "bold",
              //   },
            >
              <ListItemIcon>
                <i
                  className={`logo-icon logo-icon-2x logo-nfl-${team.abbr.toLowerCase()}`}
                />
              </ListItemIcon>
              <ListItemText primary={team.name} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </Fragment>
  );
}
