import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//mui imports
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
// local imports
import { getNFLCurrentMatchup } from '../../../api';
import MatchupHistoryTable from './MatchupHistoryTable';

export default function Current() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentMatchup, setCurrentMatchup] = useState(null);

  useEffect(() => {
    getNFLCurrentMatchup()
      .then((response) => {
        setCurrentMatchup(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setError(error);
        setIsLoading(false);
      });
  }, []);

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
        <Typography
          component="h2"
          variant="h4"
          align="center"
          justify="center"
          color="text.primary"
          gutterBottom
        >
          There was an issue loading the current NFL Title Belt matchup.
        </Typography>
      </Container>
    );
  }

  // format match date
  const convertedDate = new Date(currentMatchup?.matchDate);
  const matchupDate = convertedDate.toLocaleString('en-US', {
    timeZone: 'UTC',
    weekday: 'long',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

  const toOrdinalSuffix = (num) => {
    const int = parseInt(num),
      digits = [int % 10, int % 100],
      ordinals = ['st', 'nd', 'rd', 'th'],
      oPattern = [1, 2, 3, 4],
      tPattern = [11, 12, 13, 14, 15, 16, 17, 18, 19];
    return oPattern.includes(digits[0]) && !tPattern.includes(digits[1])
      ? int + ordinals[digits[0] - 1]
      : int + ordinals[3];
  };

  const matchupHistoryDescription = () => {
    if (
      currentMatchup?.history?.challengerWins >
      currentMatchup?.history?.holderWins
    ) {
      return `The ${currentMatchup?.challenger.name} lead the all-time series with a ${currentMatchup?.history?.challengerWins}-${currentMatchup?.history?.holderWins} record against the ${currentMatchup?.holder.name} in Title Belt matchups.`;
    }
    if (
      currentMatchup?.history?.holderWins >
      currentMatchup?.history?.challengerWins
    ) {
      return `The ${currentMatchup?.holder.name} lead the all-time series with a ${currentMatchup?.history?.holderWins}-${currentMatchup?.history?.challengerWins} record against the ${currentMatchup?.challenger.name} in Title Belt matchups.`;
    }
    if (
      currentMatchup?.history?.holderWins ===
      currentMatchup?.history?.challengerWins
    ) {
      return `The ${currentMatchup?.holder.name} and ${currentMatchup?.challenger.name} have split the all-time series with a ${currentMatchup?.history?.holderWins}-${currentMatchup?.history?.challengerWins} record in Title Belt matchups.`;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ pt: 8, pb: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs="12">
          <Typography
            component="h2"
            variant="h4"
            align="center"
            justify="center"
            color="text.primary"
            gutterBottom
          >
            {isLoading ? <Skeleton /> : matchupDate}
          </Typography>
        </Grid>
        <Grid item xs="12">
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Link
                to={`/nfl/${currentMatchup?.holder?.slug}`}
                variant="body"
                style={{ textDecoration: 'inherit' }}
              >
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" height={75} width={75} />
                  ) : (
                    <i
                      className={`logo-icon logo-icon-large logo-nfl-${currentMatchup?.holder?.abbr?.toLowerCase()}`}
                    />
                  )}
                  <Typography
                    component="h3"
                    variant="h5"
                    align="center"
                    color="text.primary"
                    gutterBottom
                  >
                    {isLoading ? (
                      <Skeleton width={225} />
                    ) : (
                      currentMatchup?.holder?.name
                    )}
                  </Typography>
                </Stack>
              </Link>
            </Grid>
            <Grid style={{ margin: 'auto' }} item xs={2}>
              <Typography
                component="h3"
                variant="h5"
                align="center"
                color="text.primary"
                gutterBottom
              >
                {isLoading ? <Skeleton /> : 'vs.'}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Link
                to={`/nfl/${currentMatchup?.challenger?.slug}`}
                variant="body"
                style={{ textDecoration: 'inherit' }}
              >
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                  {isLoading ? (
                    <Skeleton variant="rectangular" height={75} width={75} />
                  ) : (
                    <i
                      className={`logo-icon logo-icon-large logo-nfl-${currentMatchup?.challenger?.abbr?.toLowerCase()}`}
                    />
                  )}
                  <Typography
                    component="h3"
                    variant="h5"
                    align="center"
                    color="text.primary"
                    gutterBottom
                  >
                    {isLoading ? (
                      <Skeleton width={225} />
                    ) : (
                      currentMatchup?.challenger?.name
                    )}
                  </Typography>
                </Stack>
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs="12">
          <Typography variant="p" align="left" color="text.primary" paragraph>
            {isLoading ? (
              <Skeleton />
            ) : (
              `
              The ${currentMatchup?.holder.name} put their
              ${currentMatchup?.holder.reignLength} week reign on the line
              against the ${currentMatchup?.challenger.name}.`
            )}
          </Typography>
          <Typography variant="p" align="left" color="text.primary" paragraph>
            {isLoading ? (
              <Skeleton />
            ) : currentMatchup?.history?.matchupCount === 0 ? (
              `This is the first Title Belt matchup between these two franchises.`
            ) : (
              `This is the ${toOrdinalSuffix(
                currentMatchup?.history?.matchupCount + 1
              )} Title Belt matchup between these two franchises. ${matchupHistoryDescription()}`
            )}
          </Typography>
          {currentMatchup?.history?.matchupCount > 0 && (
            <Grid item xs="12">
              <MatchupHistoryTable
                history={currentMatchup?.history}
                isLoading={isLoading}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
