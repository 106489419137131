const allFranchises = [
  {
    abbr: "ARI",
    conference: "NFC",
    division: "West",
    league: "NFL",
    name: "Arizona Cardinals",
    slug: "arizona-cardinals",
  },
  {
    abbr: "ATL",
    conference: "NFC",
    division: "South",
    league: "NFL",
    name: "Atlanta Falcons",
    slug: "atlanta-falcons",
  },
  {
    abbr: "BAL",
    conference: "AFC",
    division: "North",
    league: "NFL",
    name: "Baltimore Ravens",
    slug: "baltimore-ravens",
  },
  {
    abbr: "BUF",
    conference: "AFC",
    division: "East",
    league: "NFL",
    name: "Buffalo Bills",
    slug: "buffalo-bills",
  },
  {
    abbr: "CAR",
    conference: "NFC",
    division: "South",
    league: "NFL",
    name: "Carolina Panthers",
    slug: "carolina-panthers",
  },
  {
    abbr: "CHI",
    conference: "NFC",
    division: "North",
    league: "NFL",
    name: "Chicago Bears",
    slug: "chicago-bears",
  },
  {
    abbr: "CIN",
    conference: "AFC",
    division: "North",
    league: "NFL",
    name: "Cincinnati Bengals",
    slug: "cincinnati-bengals",
  },
  {
    abbr: "CLE",
    conference: "AFC",
    division: "North",
    league: "NFL",
    name: "Cleveland Browns",
    slug: "cleveland-browns",
  },
  {
    abbr: "DAL",
    conference: "NFC",
    division: "East",
    league: "NFL",
    name: "Dallas Cowboys",
    slug: "dallas-cowboys",
  },
  {
    abbr: "DEN",
    conference: "AFC",
    division: "West",
    league: "NFL",
    name: "Denver Broncos",
    slug: "denver-broncos",
  },
  {
    abbr: "DET",
    conference: "NFC",
    division: "North",
    league: "NFL",
    name: "Detroit Lions",
    slug: "detroit-lions",
  },
  {
    abbr: "GNB",
    conference: "NFC",
    division: "North",
    league: "NFL",
    name: "Green Bay Packers",
    slug: "green-bay-packers",
  },
  {
    abbr: "HOU",
    conference: "AFC",
    division: "South",
    league: "NFL",
    name: "Houston Texans",
    slug: "houston-texans",
  },
  {
    abbr: "IND",
    conference: "AFC",
    division: "South",
    league: "NFL",
    name: "Indianapolis Colts",
    slug: "indianapolis-colts",
  },
  {
    abbr: "JAC",
    conference: "AFC",
    division: "South",
    league: "NFL",
    name: "Jacksonville Jaguars",
    slug: "jacksonville-jaguars",
  },
  {
    abbr: "KAN",
    conference: "AFC",
    division: "West",
    league: "NFL",
    name: "Kansas City Chiefs",
    slug: "kansas-city-chiefs",
  },
  {
    abbr: "LVR",
    conference: "AFC",
    division: "West",
    league: "NFL",
    name: "Las Vegas Raiders",
    slug: "las-vegas-raiders",
  },
  {
    abbr: "LAC",
    conference: "AFC",
    division: "West",
    league: "NFL",
    name: "Los Angeles Chargers",
    slug: "los-angeles-chargers",
  },
  {
    abbr: "LAR",
    conference: "NFC",
    division: "West",
    league: "NFL",
    name: "Los Angeles Rams",
    slug: "los-angeles-rams",
  },
  {
    abbr: "MIA",
    conference: "AFC",
    division: "East",
    league: "NFL",
    name: "Miami Dolphins",
    slug: "miami-dolphins",
  },
  {
    abbr: "MIN",
    conference: "NFC",
    division: "North",
    league: "NFL",
    name: "Minnesota Vikings",
    slug: "minnesota-vikings",
  },
  {
    abbr: "NWE",
    conference: "AFC",
    division: "East",
    league: "NFL",
    name: "New England Patriots",
    slug: "new-england-patriots",
  },
  {
    abbr: "NOR",
    conference: "NFC",
    division: "South",
    league: "NFL",
    name: "New Orleans Saints",
    slug: "new-orleans-saints",
  },
  {
    abbr: "NYG",
    conference: "NFC",
    division: "East",
    league: "NFL",
    name: "New York Giants",
    slug: "new-york-giants",
  },
  {
    abbr: "NYJ",
    conference: "AFC",
    division: "East",
    league: "NFL",
    name: "New York Jets",
    slug: "new-york-jets",
  },
  {
    abbr: "PHI",
    conference: "NFC",
    division: "East",
    league: "NFL",
    name: "Philadelphia Eagles",
    slug: "philadelphia-eagles",
  },
  {
    abbr: "PIT",
    conference: "AFC",
    division: "North",
    league: "NFL",
    name: "Pittsburgh Steelers",
    slug: "pittsburgh-steelers",
  },
  {
    abbr: "SFO",
    conference: "NFC",
    division: "West",
    league: "NFL",
    name: "San Francisco 49ers",
    slug: "san-francisco-49ers",
  },
  {
    abbr: "SEA",
    conference: "NFC",
    division: "West",
    league: "NFL",
    name: "Seattle Seahawks",
    slug: "seattle-seahawks",
  },
  {
    abbr: "TAM",
    conference: "NFC",
    division: "South",
    league: "NFL",
    name: "Tampa Bay Buccaneers",
    slug: "tampa-bay-buccaneers",
  },
  {
    abbr: "TEN",
    conference: "AFC",
    division: "South",
    league: "NFL",
    name: "Tennessee Titans",
    slug: "tennessee-titans",
  },
  {
    abbr: "WAS",
    conference: "NFC",
    division: "East",
    league: "NFL",
    name: "Washington Football Team",
    slug: "washington-football-team",
  },
];

export const afcEast = {
  name: "AFC East",
  teams: allFranchises.filter(
    (franchise) =>
      franchise.conference === "AFC" && franchise.division === "East"
  ),
};
export const afcNorth = {
  name: "AFC North",
  teams: allFranchises.filter(
    (franchise) =>
      franchise.conference === "AFC" && franchise.division === "North"
  ),
};
export const afcSouth = {
  name: "AFC South",
  teams: allFranchises.filter(
    (franchise) =>
      franchise.conference === "AFC" && franchise.division === "South"
  ),
};
export const afcWest = {
  name: "AFC West",
  teams: allFranchises.filter(
    (franchise) =>
      franchise.conference === "AFC" && franchise.division === "West"
  ),
};
export const nfcEast = {
  name: "NFC East",
  teams: allFranchises.filter(
    (franchise) =>
      franchise.conference === "NFC" && franchise.division === "East"
  ),
};
export const nfcNorth = {
  name: "NFC North",
  teams: allFranchises.filter(
    (franchise) =>
      franchise.conference === "NFC" && franchise.division === "North"
  ),
};
export const nfcSouth = {
  name: "NFC South",
  teams: allFranchises.filter(
    (franchise) =>
      franchise.conference === "NFC" && franchise.division === "South"
  ),
};
export const nfcWest = {
  name: "NFC West",
  teams: allFranchises.filter(
    (franchise) =>
      franchise.conference === "NFC" && franchise.division === "West"
  ),
};

export const allFranchisesByDivision = [
  afcEast,
  afcNorth,
  afcSouth,
  afcWest,
  nfcEast,
  nfcNorth,
  nfcSouth,
  nfcWest,
];

export default allFranchisesByDivision;
